<script setup lang="ts">
export interface AppButtonProps {
  label?: string
  icon?: string
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  size?: 'default' | 'xs' | 'sm' | 'lg' | 'icon'
  color?: 'default' | 'primary' | 'secondary' | 'info' | 'neutral' | 'error' | 'success'
  shape?: 'default' | 'circle' | 'square'
  variant?: 'default' | 'outline' | 'ghost' | 'link'
  to?: string
}

const props = withDefaults(defineProps<AppButtonProps>(), {
  variant: 'default',
  size: 'default',
  disabled: false,
  iconPosition: 'left'
})

const sizesClasses = computed(() => {
  switch (props.size) {
    case 'default':
      return {
        text: 'btn-md',
        icon: 'text-lg'
      }
    case 'xs':
      return {
        text: 'btn-xs',
        icon: 'text-xs'
      }
    case 'sm':
      return {
        text: 'btn-sm',
        icon: 'text-sm'
      }
    case 'lg':
      return {
        text: 'btn-lg',
        icon: 'text-xl'
      }
    case 'icon':
      return {
        text: 'btn-sm',
        icon: 'text-sm'
      }
    default:
      return {
        text: 'btn-md',
        icon: 'text-lg'
      }
  }
})

const btnColor = computed(() => {
  switch (props.color) {
    case 'primary':
      return 'btn-primary'
    case 'secondary':
      return 'btn-secondary'
    case 'info':
      return 'btn-info'
    case 'neutral':
      return 'btn-neutral'
    case 'error':
      return 'btn-error'
    case 'success':
      return 'btn-success'
    default:
      return 'btn-primary'
  }
})

const btnVariant = computed(() => {
  switch (props.variant) {
    case 'outline':
      return 'btn-outline'
    case 'ghost':
      return 'btn-ghost'
    case 'link':
      return 'btn-link'
    default:
      return ''
  }
})

const btnShape = computed(() => {
  switch (props.shape) {
    case 'circle':
      return 'btn-circle'
    case 'square':
      return 'btn-square'
    default:
      return ''
  }
})

const labelToShow = computed(() => {
  return props.size !== 'icon' ? props.label : ''
})
</script>

<template>
  <NuxtLink v-if="to" :to="to">
    <button :disabled="disabled" class="btn flex items-center justify-center" :class="`${sizesClasses?.text} ${btnColor} ${btnVariant} ${btnShape}`">
      <iconify-icon v-if="icon && iconPosition === 'left'" :icon="icon" :class="labelToShow && 'mr-2'" />
      <slot>{{ labelToShow ?? '' }}</slot>
      <iconify-icon v-if="icon && iconPosition === 'right'" :icon="icon" :class="labelToShow && 'ml-2'" />
    </button>
  </NuxtLink>
  <button v-else :disabled="disabled" class="btn flex items-center justify-center" :class="`${sizesClasses?.text} ${btnColor} ${btnVariant} ${btnShape}`">
    <iconify-icon v-if="icon && iconPosition === 'left'" :icon="icon" :class="`${labelToShow && 'mr-2'} ${sizesClasses.icon}`" />
    <slot>{{ labelToShow ?? '' }}</slot>
    <iconify-icon v-if="icon && iconPosition === 'right'" :icon="icon" :class="`${labelToShow && 'ml-2'} ${sizesClasses.icon}`" />
  </button>
</template>

<style scoped>

</style>
